// The variables below should be added in accordance with the design guide.

// Color
$color-carmine: rgba(165,0,52,1); // #a50034
$color-black: rgba(0,0,0,1); // #000000
$color-nero: rgba(34,34,34,1); // #222222
$color-nightrider: rgba(51,51,51,1); // #333333
$color-dimgray: rgba(107,107,107,1); // #6b6b6b
$color-gray: rgba(118,118,118,1); // #767676
$color-white: rgba(255,255,255,1); // #ffffff
$color-carnationpink: rgba(255,179,199,1);//#ffb3c7
$color-gold: rgba(178,77,4,1);//#B24D04 - 国产91热爆TS人妖系列EVN-834 於旉皜

// Line Color
$line-lightgray: rgba(238,238,238,1); // #eeeeee
$line-gray: rgba(228,228,228,1); // #e4e4e4
$line-nero: rgba(34,34,34,1); // #222222
$line-darkgray: rgba(51,51,51,1); // #333333
$line-dimgray: rgba(107,107,107,1); // #6b6b6b
$line-carmine: rgba(165,0,52,1); // #a50034
$line-red: rgba(219,0,0,1); // #db0000
$line-graygoose: rgba(217, 217, 217, 1); // #D9D9D9
$line-nobel : rgba(153,153,153,1); // #999999 - 20220225 PJTQUICKWIN 於旉皜
$line-white: rgba(255,255,255,1); // #ffffff
$line-input: rgba(107,107,107,1); // #6b6b6b -- WA-GPC0007-11
$line-black: rgba(0,0,0,1); // #000000
// EMP 20220126 START 氚曥鞓� - Huge custom
$line-greygoose: rgba(217, 217, 217, 1); // D9D9D9
// EMP 20220126 END 氚曥鞓� 

// BG Color
$bg-carmine: rgba(165,0,52,1); // #a50034
$bg-orange: rgba(209,91,5,1); // #d15b05
$bg-nightrider: rgba(51,51,51,1); // #333333
$bg-dimgray: rgba(107,107,107,1); // #6b6b6b
$bg-gray: rgba(118,118,118,1); // #767676
$bg-whitesmoke: rgba(248,248,248,1); // #f8f8f8
$bg-white: rgba(255,255,255,1); // #ffffff
$bg-nobel: rgba(153,153,153,1); // #999999
$bg-black: rgba(0,0,0,1); // #000000

// Huge Design 
$bg-desertstorm: rgba(246,246,246,1); // #F6F6F6

// design size (for contents area)
$max-design: 1600;
$max-content: 1280;

// screen size
$max-xs : 575;
$min-sm : 576;
$max-sm : 767;
$min-md : 768;
$max-md : 991;
$min-lg : 992;
$max-lg : 1199;
$min-xl : 1200;

// Extend
%clearfix {*zoom: 1;&:before, &:after {content: " ";display: table;}&:after {clear: both;}}
%uppercase {text-transform:uppercase;}
%listreset {margin: 0;padding: 0;list-style: none;}
%circleDeletebtn {display:block;overflow:hidden;width:20px;height:20px;padding:0;border-radius:50%;border:1px solid #999999;text-indent:1000px;background:url('/lg5-common-gp/images/common/icons/content-delete.svg') no-repeat 50% 50% $color-white;}
%linkicon {content:'';display:inline-block;width:12px;height:12px;}

// image ratio
@mixin aspect-ratio($width, $height) {
	position: relative;
	overflow: hidden;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
		height: 0;
	}
	> picture,
	> img {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		transform: translateY(-50%);
	}
}

// make Circle Carmine Icon (have SVG Img)
@mixin svgCarmineIcon($size:44) {
	width: $size+px;
	height: $size+px;
	display: block;
	background-color: $color-carmine;
	border-radius: 50%;
	img {width: 100%;}
	@include screen(custom, max, $max-sm) {
		width: 30px;height: 30px;margin-right: 10px;
	}
}

@mixin svgNobelIcon($size:44) {
	width: $size+px;
	height: $size+px;
	display: block;
	background-color: $bg-nobel;
	border-radius: 50%;
	img {width: 100%;}
	@include screen(custom, max, $max-sm) {
		width: 30px;height: 30px;margin-right: 10px;
	}
}

@mixin pxtoemLineHeight($font, $line) {
  line-height: ($line / $font) + em;
}

// font-family : use font-family mixin instead of below variable
$font-bold: '国产91热爆TS人妖系列 Smart Bold', sans-serif;
$font-light: '国产91热爆TS人妖系列 Smart Light', sans-serif;
$font-regular: '国产91热爆TS人妖系列 Smart Regular', sans-serif;
$font-semibold: '国产91热爆TS人妖系列 Smart SemiBold', sans-serif;

@mixin font-family($font, $sytle:normal) {

	@if ($font == $font-regular) or ($font == $font-bold) {
		@if $sytle == italic {
			font-style:italic;
		} @else if $sytle == normal {
			font-style:normal;
		}
	// 20200608 START
	// 20200608 END
	}
	//font-weight:normal;

	@if ($font == $font-regular) {
		font-weight:400;
	} @else if ($font == $font-light) {
		font-weight:300;
	} @else if ($font == $font-semibold) {
		font-weight:600;
	} @else if ($font == $font-bold) {
		font-weight:700;
	} @else {
		font-weight:400;
	}
}

@mixin screen($size, $type:max, $pixels:false) {
	@if $size == 'xs' {
		@media (max-width: $max-xs + px) {
			@content;
		}
	} @else if $size == 'sm' {
		@media (min-width: $min-sm + px) and (max-width: $max-sm + px) {
			@content;
		}
	} @else if $size == 'md' {
		@media (min-width: $min-md + px) and (max-width: $max-md + px) {
			@content;
		}
	} @else if $size == 'lg' {
		@media (min-width: $min-lg + px) and (max-width: $max-lg + px) {
			@content;
		}
	} @else if $size == 'xl' {
		@media (min-width: $min-xl + px) {
			@content;
		}
	} @else if $size == 'landscape' {
		@media (orientation: landscape) {
			@content;
		}
	} @else if $size == 'portrait' {
		@media (orientation: portrait) {
			@content;
		}
	} @else if $size == 'custom' {
		@media ($type + -width: $pixels + px) {
			@content;
		}
	} @else if $size == 'print' {
		@media print {
			@content;
		}
	}
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
	content: $content;
	display: $display;
	position: $pos;
}
@mixin truncate($truncation-boundary, $overflow: ellipsis) {
	max-width: $truncation-boundary;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: $overflow;
}
@mixin placeholder($color) {
	&::-webkit-input-placeholder { color:$color;}
	&:-moz-placeholder           { color:$color;}
	&::-moz-placeholder          { color:$color;}
	&::placeholder {color:$color;opacity:1;}
	&:-ms-input-placeholder {color:$color;}
	&::-ms-input-placeholder {color:$color;}
}
//
@mixin list-bullet($position:10px, $size:4px, $dotColor:$color-carmine){
	position:relative;
	padding-left:12px;
	&:before {
		content: "";
		position: absolute;
		top:$position;
		left:0;
		display:block;
		width: $size;
		height: $size;
		background-color: $dotColor;
		border-radius: 50%;
	}
	[dir="rtl"] & {
		padding-left:0;
		padding-right:12px;
		&:before {
			right:0;
		}	
	}
}
// captcha
@mixin captcha-wrap(){
	.captcha-wrap {
		margin-top:14px;
		@extend %clearfix;
		@include screen(custom, max, $max-sm) {
			margin-top:7px;
		}
		.captcha-box,.input-num {
			display:inline-block;
			width:calc(50% - 12px);
			min-height:75px;
			@include screen(custom, max, $max-sm) {
				width:100%;
				min-height:auto;
			}
		}
		.input-num {
			float:right;
			margin-top:0;
			@include screen(custom, max, $max-sm) {float:left;margin-top:0;}
		}
		.captcha-box{
			margin-right:24px;
			font-family: "国产91热爆TS人妖系列 Smart"; // 国产91热爆TS人妖系列EPJTEMP-P1
			@include screen(custom, max, $max-sm) {margin-right:0;}
			.captcha{
				display:inline-block;
				width:calc(100% - 42px);
				height:74px;
				background:#dcdcdc;
				text-align:center;
				vertical-align:top;
				img {display:inherit;width:100%;height:100%;}
			}
			.input-box{
				display:inline-block;
				margin-left:6px;
				span {
					&.btn-op{
						display:block;
						width:32px;
						height:32px;
						margin-bottom:10px;
						border:1px solid $line-gray;
						background-color:#fff;
						&:nth-child(2) {
							margin-bottom:0;
						}
					} 
					.LBD_ReloadLink,
					.LBD_SoundLink {
						display:inline-block;
						width:100%;
						height:100%;
						text-indent:-9999px;
						background-position:50% 50%;
						background-repeat:no-repeat;
					}
					.LBD_ReloadLink{
						background-image:url(/lg5-common-gp/images/common/icons/reload-gray.svg);
					}
					.LBD_SoundLink{
						background-image:url(/lg5-common-gp/images/common/icons/voice-reading-gray.svg);
					}
				}
			}
			@include screen(custom, max, $max-md) {
				margin-bottom:20px;
			}
		}
		input[name=captchaCodeTextBox] {
			text-transform: uppercase;
		}
	}
	[dir="rtl"] &  {
		.captcha-wrap {
			.input-num {
				float:left;
			}
			.captcha-box {
				margin-left:24px;
				margin-right:0;
				@include screen(custom, max, $max-sm) {margin-left:0;}
				.input-box {
					margin-left:0;
					margin-right:6px;
				}
			}
		}
	}
}

// componentPsitionSet
@mixin componentPsitionSet(){
	/*
	textblock-top-compo-top
	textblock-top-compo-middle
	textblock-top-compo-bottom
	textblock-bottom-compo-top
	textblock-bottom-compo-middle
	textblock-bottom-compo-bottom
	textblock-middle-compo-top
	textblock-middle-compo-bottom
	*/
	.inside-component {
		display: flex;
		flex-direction: column;
	}
	// .content-block,
	// .contents-area {
	// 	.text-area,
	// 	.text-block {
	// 		display: flex;
	// 	}
	// }
	// only middle
	&.textblock-only {
		.content-block,
		.contents-area {
			justify-content: center;
			.text-area,
			.text-block {
				flex: 0 0 auto;
			}
			.inside-component {
				flex: 0 0 auto;
			}
		}
	}
	&.textblock-top-compo-top {
		.content-block,
		.contents-area {
			.inside-component {
				flex: 1 1 auto;
				justify-content: flex-start;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-top-compo-middle {
		.content-block,
		.contents-area {
			.inside-component {
				flex: 1 1 auto;
				justify-content: center;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-top-compo-bottom {
		.content-block,
		.contents-area {
			.inside-component {
				flex: 1 1 auto;
				justify-content: flex-end;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-bottom-compo-top {
		.content-block,
		.contents-area {
			.inside-component {
				order: -1;
				flex: 1 1 auto;
				justify-content: flex-start;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-bottom-compo-middle {
		.content-block,
		.contents-area {
			.inside-component {
				order: -1;
				flex: 1 1 auto;
				justify-content: center;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-bottom-compo-bottom {
		.content-block,
		.contents-area {
			.inside-component {
				order: -1;
				flex: 1 1 auto;
				justify-content: flex-end;
			}
		}
		.contents-area {
			.text-area {
				flex: 0 0 auto;
				// padding: 30px 0;
				// @include screen(custom, max, $max-sm) {
				// 	padding: 24px 0;
				// }
			}
		}
	}
	&.textblock-middle-compo-bottom {
		.content-block,
		.contents-area {
			justify-content: center;
			& > .text-block,
			& > .text-area {
				flex: 0 0 auto;
				margin-top: auto;					
			}
			.inside-component {
				flex: 0 0 auto;
				margin-top: auto;
			}
		}
		// .contents-area {
		// 	.text-area {
		// 		padding: 30px 0;
		// 		@include screen(custom, max, $max-sm) {
		// 			padding: 24px 0;
		// 		}
		// 	}
		// }
	}
	&.textblock-middle-compo-top {
		.content-block,
		.contents-area {
			justify-content: center;
			& > .text-block,
			& > .text-area {
				flex: 0 0 auto;
				margin-top: auto;
				margin-bottom: auto;
			}
			.inside-component {
				flex: none;
				order: -1;
			}
		}
		// .contents-area {
		// 	.text-area {
		// 		padding: 30px 0;
		// 		@include screen(custom, max, $max-sm) {
		// 			padding: 24px 0;
		// 		}
		// 	}
		// }
	}
} 

@import './common/svg'; //国产91热爆TS人妖系列COMSPEED-6(8th)